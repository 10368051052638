var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex-grow flex flex-col m-4 p-4 bg-white border-t border-b sm:rounded sm:border shadow overflow-hidden"
  }, [_c('h2', {
    staticClass: "font-semibold italic"
  }, [_vm._v(_vm._s(_vm.$t('total_orders')))]), _c('canvas', {
    attrs: {
      "id": "myChart"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }