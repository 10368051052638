var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('div', {
    staticClass: "flex items-center justify-center px-5 py-5"
  }, [_c('div', {
    staticClass: "w-full mx-auto h-auto rounded-xl bg-white shadow-sm p-10 text-gray-800 relative overflow-hidden"
  }, [_c('div', {
    staticClass: "relative mt-1 teleconsultant"
  }, [_c('div', {
    staticClass: "grid grid-cols-4 gap-4"
  }, [_vm.currentUser.type != 'TeleConsultant' && _vm.currentUser.type != 'CancelledOrder' ? _c('v-select', {
    staticClass: "input-style",
    attrs: {
      "label": "fullName",
      "options": _vm.TabTeleConsultant,
      "placeholder": _vm.$t('Select TeleConsultant')
    },
    on: {
      "input": _vm.setSelected
    },
    model: {
      value: _vm.formData.teleConsultant,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "teleConsultant", $$v);
      },
      expression: "formData.teleConsultant"
    }
  }) : _vm._e(), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.duree,
      expression: "duree"
    }],
    staticClass: "input-style",
    attrs: {
      "id": "type",
      "name": "date",
      "autocomplete": "type"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.duree = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.ChangeTypeDate($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": "Today"
    }
  }, [_vm._v(_vm._s(_vm.$t("today")))]), _c('option', {
    attrs: {
      "value": "Yesterday"
    }
  }, [_vm._v(_vm._s(_vm.$t("yesterday")))]), _c('option', {
    attrs: {
      "value": "This Month"
    }
  }, [_vm._v(_vm._s(_vm.$t("this_month")))]), _c('option', {
    attrs: {
      "value": "Last Month"
    }
  }, [_vm._v(_vm._s(_vm.$t("last_month")))]), _c('option', {
    attrs: {
      "value": "This Year"
    }
  }, [_vm._v(_vm._s(_vm.$t("this_year")))]), _c('option', {
    attrs: {
      "value": "Last Year"
    }
  }, [_vm._v(_vm._s(_vm.$t("last_year")))])]), _c('date-picker', {
    staticClass: "w-full-imp input-style",
    attrs: {
      "placeholder": "Range date ...",
      "format": "YYYY-MM-DD",
      "type": "date",
      "range": ""
    },
    model: {
      value: _vm.formData.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "startDate", $$v);
      },
      expression: "formData.startDate"
    }
  }), _c('button', {
    staticClass: "border font-semiborder-indigo-500 bg-indigo-500 text-white rounded-md transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.getTotalStatusPerTeleconsultantBetweenTwoDate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("apply")) + " ")])], 1)]), _vm._m(0), _c('div', {
    staticClass: "p-4"
  }, [_c('div', {
    staticClass: "bg-white w-full"
  }, [_c('div', {
    staticClass: "antialiased bg-white mt-8"
  }, [_c('div', {
    staticClass: "h-20 flex items-center"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('div', {
    staticClass: "text-2xl font-bold text-green-700"
  }, [_vm._v(" " + _vm._s(_vm.$t("teleconsultant")) + " ")])]), _c('div', {
    staticClass: "w-full flex justify-end"
  }, [_c('div', {
    staticClass: "ml-2 cursor-pointer flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300",
    class: {
      'cursor-not-allowed pointer-events-none': _vm.TeleConsultantTab.length == 0
    },
    on: {
      "click": _vm.downloadAnalytics
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("file_download")]), _vm._v(" csv ")])])]), _c('div', {
    staticClass: "-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto"
  }, [_c('div', {
    staticClass: "inline-block min-w-full rounded-lg overflow-hidden relative"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "table-responsive custom-table-responsive"
  }, [_c('table', {
    staticClass: "table custom-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "uppercase text-left",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("user")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nb_order_traits")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("nb calls")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancelled")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirmed")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("to_remind")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("unreached")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("delivered")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("paid")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("processed")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("return")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("shipped")) + " ")]), _c('th', {
    staticClass: "uppercase text-center",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")])])]), _c('tbody', [_vm._l(_vm.TeleConsultantTab, function (item, index) {
    return _c('tr', {
      key: index,
      attrs: {
        "scope": "row"
      }
    }, [_c('td', {
      staticClass: "border-t-b-l"
    }, [_c('div', {
      staticClass: "flex"
    }, [_c('div', {
      staticClass: "w-10 h-10 image-fit zoom-in"
    }, [_c('div', {
      staticClass: "mr-3"
    }, [_c('a', {
      staticClass: "btn btn-circle btn-danger text-white uppercase",
      class: 'bg-' + _vm.getBgColor()
    }, [_vm._v(_vm._s(_vm.nameToInitials(_vm.DataParse(item.userData).fullName)))])])]), _c('div', {
      staticClass: "flex-wrap image-fit zoom-in"
    }, [_c('p', {
      staticClass: "flex w-full font-medium whitespace-nowrap uppercase"
    }, [_vm.DataParse(item.userData).fullName ? _c('span', [_vm._v(" " + _vm._s(_vm.DataParse(item.userData).fullName) + " ")]) : _c('span', [_vm._m(1, true)])])])])]), _c('td', {
      staticClass: "text-center border-t-b"
    }, [_vm._v(" " + _vm._s(item.countAll) + " ")]), _c('td', {
      staticClass: "text-center border-t-b"
    }, [_c('p', {
      staticClass: "text-gray-900 whitespace-no-wrap font-bold"
    }, [_c('i', {
      staticClass: "material-icons mr-2",
      staticStyle: {
        "font-size": "19px"
      }
    }, [_vm._v("local_phone")]), _vm._v(_vm._s(item.callAll) + " ")])]), _vm._l(item.status, function (it, indx) {
      return _c('td', {
        key: indx,
        staticClass: "text-center border-t-b"
      }, [_c('span', {
        staticClass: "text-gray-900 rounded-full"
      }, [_c('Progress', {
        attrs: {
          "strokeColor": _vm.getColor(it.name),
          "radius": 40,
          "strokeWidth": 10,
          "value": _vm.getPercentege(it.name, it.count, item.countAll, item.callAll)
        },
        scopedSlots: _vm._u([{
          key: "footer",
          fn: function fn() {
            return [_vm._v(" " + _vm._s(_vm.$t("total")) + ": " + _vm._s(it.count) + " ")];
          },
          proxy: true
        }], null, true)
      })], 1)]);
    }), _c('td', {
      staticClass: "border-t-b-r"
    }, [_c('a', {
      staticClass: "text-blue-400 hover:text-blue-700 underline",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.Detials(item);
        }
      }
    }, [_c('i', {
      staticClass: "material-icons mx-1"
    }, [_vm._v("remove_red_eye")])])])], 2);
  }), _vm._m(2), _vm.TeleConsultantTab.length > 0 ? _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("total")) + " :")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.countOrderCancAndConf) + " ")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.countOrder))]), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('Cancelled'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderCan, _vm.countOrder,
      //countOrderCan + countOrderConf,
      null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderCan) + " ")];
      },
      proxy: true
    }], null, false, 2708138612)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('Confirmed'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderConf, _vm.countOrder,
      //countOrderConf + countOrderConf,
      null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderConf) + " ")];
      },
      proxy: true
    }], null, false, 3141707644)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('ToRemind'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderToRem, _vm.countOrder,
      //countOrderToRem + countOrderUnr,
      null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderToRem) + " ")];
      },
      proxy: true
    }], null, false, 1429263353)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('Unreached'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderUnr, _vm.countOrder,
      // countOrderToRem + countOrderUnr,
      null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderUnr) + " ")];
      },
      proxy: true
    }], null, false, 253584721)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('delivered'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderDeli, _vm.countOrder, null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderDeli) + " ")];
      },
      proxy: true
    }], null, false, 2119063196)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('paid'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderpaid, _vm.countOrder, null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderpaid) + " ")];
      },
      proxy: true
    }], null, false, 1000735044)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('processed'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderProce, _vm.countOrder, null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderProce) + " ")];
      },
      proxy: true
    }], null, false, 2840830003)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('return'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderReturn, _vm.countOrder, null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderReturn) + " ")];
      },
      proxy: true
    }], null, false, 617276498)
  })], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('Progress', {
    attrs: {
      "strokeColor": _vm.getColor('shipped'),
      "radius": 40,
      "strokeWidth": 10,
      "value": _vm.getPercentege(null, _vm.countOrderShi, _vm.countOrder, null)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.countOrderShi) + " ")];
      },
      proxy: true
    }], null, false, 3633030410)
  })], 1), _c('td')]) : _vm._e()], 2)])]), _vm.TeleConsultantTab.length <= 0 ? _c('div', {
    staticClass: "bg-blue-100 py-8"
  }, [_vm._m(3)]) : _vm._e()], 1)])])])])])])]), _c('vue-modal-2', {
    attrs: {
      "name": "ModalInfoTeleconsulatant",
      "headerOptions": {
        title: "TeleConsultant: ".concat(_vm.infoTeleconsultant.user.name)
      },
      "noFooter": true,
      "modalSize": "xl"
    },
    on: {
      "on-close": function onClose($event) {
        return _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "w-full bg-white rounded-lg p-4 pt-0 pb-0"
  }, [_c('div', {
    staticClass: "items-center"
  }, [_c('div', {
    staticClass: "grid grid-flow-col gap-6"
  }, [_c('div', {
    staticClass: "card card-block card-stretch card-height relative text-primary"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "is-full-page": _vm.fullPage
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "card-body bg-primary-light rounded"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "rounded iq-card-icon bg-primary"
  }, [_c('img', {
    staticClass: "img-call",
    attrs: {
      "src": "https://img.icons8.com/ios-filled/64/FFFFFF/headset.png"
    }
  })]), _c('div', {
    staticClass: "text-right"
  }, [_c('h2', {
    staticClass: "mb-0"
  }, [_c('span', {
    staticClass: "counter",
    staticStyle: {
      "visibility": "visible"
    }
  }, [_vm._v(_vm._s(_vm.getPercentege(null, _vm.infoTeleconsultant.confirmed, _vm.infoTeleconsultant.confirmed + _vm.infoTeleconsultant.cancelled)) + "%")])]), _c('h5', {}, [_vm._v("Confirmation Rate")])])]), _c('div', {
    staticClass: "separator separator-dashed my-2"
  }), _c('div', {
    staticClass: "d-flex flex-stack"
  }, [_c('div', {
    staticClass: "text-info-line fw-semibold fs-6 me-2 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info-line"
  }), _c('span', {
    staticClass: "text-gray-900 fw-bolder fs-6 mr-1"
  }, [_vm._v(_vm._s(_vm.infoTeleconsultant.confirmed) + " ")]), _vm._v(" Confirmed ")]), _c('div', {
    staticClass: "d-flex align-items-senter"
  }, [_c('div', {
    staticClass: "text-cancelled-line fw-semibold fs-6 me-2 d-flex align-items-center"
  }, [_c('span', {
    staticClass: "bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-cancelled-line"
  }), _c('span', {
    staticClass: "text-gray-900 fw-bolder fs-6 mr-1"
  }, [_vm._v(_vm._s(_vm.infoTeleconsultant.cancelled) + " ")]), _vm._v(" Cancelled ")])])])])], 1)])]), _c('div-table', {
    attrs: {
      "config": _vm.configList,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser,
      "data": _vm.infoTeleconsultant.dataOrders,
      "loading": _vm.infoTeleconsultant.loading,
      "paginate": _vm.paginate
    },
    on: {
      "paginatation": _vm.paginatation,
      "refresh": _vm.refresh
    }
  })], 1)])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "absolute top-0 left-0 w-full h-2 flex"
  }, [_c('div', {
    staticClass: "h-1 bg-green-600 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-yellow-500 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-green-600 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-yellow-500 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-green-600 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-yellow-500 flex-1"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lds-ellipsis"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "spacer"
  }, [_c('td', {
    attrs: {
      "colspan": "100"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "w-full flex justify-center text-center py-3 border-b border-gray-200 text-base"
  }, [_c('p', {
    staticClass: "font-semibold flex text-green-500 whitespace-no-wrap"
  }, [_c('i', {
    staticClass: "material-icons mx-3"
  }, [_vm._v("error")]), _c('span', {}, [_vm._v("No data found")])])]);

}]

export { render, staticRenderFns }