var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "flex items-center justify-center px-5 py-5"
  }, [_c('div', {
    staticClass: "w-full mx-auto rounded-xl bg-white shadow-sm p-10 text-gray-800 relative overflow-hidden"
  }, [_c('div', {
    staticClass: "relative mt-1"
  }, [_c('div', {
    staticClass: "flex space-x-2"
  }, [_c('select', {
    staticClass: "w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors",
    attrs: {
      "id": "type",
      "name": "date",
      "autocomplete": "type"
    }
  }, [_c('option', [_vm._v("Today")]), _c('option', [_vm._v("Yesterday")]), _c('option', [_vm._v("This Month")]), _c('option', [_vm._v("Last Month")]), _c('option', [_vm._v("This Year")]), _c('option', [_vm._v("Last Year")])]), _c('input', {
    staticClass: "w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors",
    attrs: {
      "placeholder": "Start Date",
      "onfocus": "(this.type='date')"
    }
  }), _c('input', {
    staticClass: "w-1/3 pl-3 pr-10 py-2 border-2 border-gray-200 rounded-xl hover:border-gray-300 focus:outline-none focus:border-blue-500 transition-colors",
    attrs: {
      "placeholder": "End Date",
      "onfocus": "(this.type='date')"
    }
  }), _c('button', {
    staticClass: "border w-1/5 font-semiborder-indigo-500 bg-indigo-500 text-white rounded-md transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline",
    attrs: {
      "type": "button"
    }
  }, [_vm._v(" Apply ")])])]), _c('div', {
    staticClass: "absolute top-0 left-0 w-full h-2 flex"
  }, [_c('div', {
    staticClass: "h-1 bg-green-600 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-yellow-500 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-green-600 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-yellow-500 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-green-600 flex-1"
  }), _c('div', {
    staticClass: "h-1 bg-yellow-500 flex-1"
  })])])])]);

}]

export { render, staticRenderFns }